<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="ma-5">
              <v-data-table
                :headers="headers"
                :items="users"
                :loading="loading"
                loading-text="Loading... Please wait"
                class="elevation-1"
                style="background: #f5f5f5"
              >
                <template v-slot:top>
                  <v-toolbar
                    elevation="2"
                    style="background: #f5f5f5"
                    class="ml-0"
                  >
                    <v-toolbar-title>All Merchants</v-toolbar-title>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    v-if="item.role !== 'manager'"
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:item.role="{ item }">
                  {{ item.role }}
                </template>
                <template v-slot:item.profile_status="{ item }">
                  <v-btn
                    color="red lighten-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    v-if="item.is_locked"
                    text
                    @click="unLockProfile(item)"
                  >
                    Unlock profile
                  </v-btn>

                  <v-btn
                    color="red lighten-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    v-if="!item.is_locked"
                    text
                    @click="lockProfile(item)"
                  >
                    Lock profile
                  </v-btn>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-dialog v-model="dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red lighten-2"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.is_active"
                        text
                      >
                        Make Inactive
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title
                        class="py-5 white--text"
                        dark
                        style="background-color: #002441"
                      >
                        Merchant Deactivate
                      </v-card-title>

                      <v-card-text>
                        Are you sure you want to deactivate this merchant ?
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="makeInactive(item)">
                          I accept
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="dialog1" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red lighten-2"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        v-if="!item.is_active"
                        text
                      >
                        Make Active
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title
                        class="py-5 white--text"
                        dark
                        style="background-color: #002441"
                      >
                        Merchant Activate
                      </v-card-title>

                      <v-card-text>
                        Are you sure you want to activate this merchant ?
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="makeActive(item)">
                          yes
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <!-- Add User Dialog -->
    <v-dialog v-model="usercreatedialog" max-width="750">
      <v-card>
        <v-card-title class="font-weight-bold"> Add User </v-card-title>
        <v-card-text>
          <v-form ref="userUploadform" v-model="userCreateFormModel">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="addItem.username"
                  required
                  :rules="[(v) => !!v || 'Name Required']"
                  label="Full Name"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  type="email"
                  v-model="addItem.email"
                  required
                  :rules="[(v) => !!v || 'Email Required']"
                  label="Email"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="addItem.password"
                  type="password"
                  required
                  :rules="[
                    () =>
                      addItem.password.length >= 6 ||
                      'Minimum 6 charecter is required',
                  ]"
                  label="Password"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="addItem.confirm_password"
                  type="password"
                  required
                  :rules="[
                    () =>
                      addItem.confirm_password == addItem.password ||
                      'Two Password Are Not Matched',
                  ]"
                  label="Confirm Password"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="addItem.phone_number"
                  type=""
                  required
                  :rules="[(v) => !!v || 'Contract Number Is Required']"
                  label="Contract Number"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="addItem.role"
                  label="Role"
                  required
                  :rules="[(v) => !!v || 'User Role Is Required']"
                  :items="addItem.select_role"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="addItem.role == 'manager'">
              <v-col cols="12">
                <v-radio-group v-model="addItem.option" row>
                  <v-radio label="Warehouse" value="Warehouse"> </v-radio>
                  <v-radio label="Shop" value="Shop"> </v-radio>
                </v-radio-group>
              </v-col>
              <div v-show="addItem.option == 'Warehouse'">
                <v-select
                  dense
                  v-model="addItem.addhome"
                  :items="warehouses"
                  item-text="warehouse_name"
                  label="Warehouse"
                  outlined
                  chips
                  return-object
                >
                </v-select>
              </div>
              <div v-show="addItem.option == 'Shop'">
                <v-select
                  dense
                  v-model="addItem.addhome"
                  :items="shops"
                  item-text="shop_name"
                  label="Shop"
                  outlined
                  chips
                  return-object
                >
                </v-select>
              </div>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="usercreatedialog = false"> Cancel </v-btn>
          <v-btn
            color="primary"
            @click="saveconfirm"
            :disabled="!userCreateFormModel"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit User Dialog -->
    <v-dialog v-model="Editusercreatedialog" max-width="750">
      <v-card>
        <v-card-title class="font-weight-bold"> Edit User </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="editedItem.username"
                  required
                  :rules="[(v) => !!v || 'Name Required']"
                  label="Full Name"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  type="email"
                  v-model="editedItem.email"
                  required
                  :rules="[(v) => !!v || 'Email Required']"
                  label="Email"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="editedItem.password"
                  type="password"
                  required
                  :rules="[
                    () =>
                      editedItem.password.length >= 6 ||
                      'Minimum 6 charecter is required',
                  ]"
                  label="Password"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="editedItem.confirm_password"
                  type="password"
                  required
                  :rules="[
                    () =>
                      editedItem.confirm_password == editedItem.password ||
                      'Two Password Are Not Matched',
                  ]"
                  label="Confirm Password"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="editedItem.phone_number"
                  type=""
                  required
                  :rules="[(v) => !!v || 'Contract Number Is Required']"
                  label="Contract Number"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="editedItem.role"
                  label="Role"
                  required
                  :rules="[(v) => !!v || 'User Role Is Required']"
                  :items="select_val"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <!-- <v-row v-if="addItem.role == 'manager'">
              <v-col cols="12">
                <v-radio-group v-model="addItem.option" row>
                  <v-radio label="Warehouse" value="Warehouse"> </v-radio>
                  <v-radio label="Shop" value="Shop"> </v-radio>
                </v-radio-group>
              </v-col>
              <div v-show="addItem.option == 'Warehouse'">
                <v-select
                  dense
                  v-model="addItem.addhome"
                  :items="warehouses"
                  item-text="warehouse_name"
                  label="Warehouse"
                  outlined
                  chips
                  return-object
                >
                </v-select>
              </div>
              <div v-show="addItem.option == 'Shop'">
                <v-select
                  dense
                  v-model="addItem.addhome"
                  :items="shops"
                  item-text="shop_name"
                  label="Shop"
                  outlined
                  chips
                  return-object
                >
                </v-select>
              </div>
            </v-row> -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="Editusercreatedialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="saveeditUser"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    loading: true,
    menu: false,
    menu2: false,
    dialog: false,
    snackbar: false,
    usercreatedialog: false,
    userCreateFormModel: false,
    Editusercreatedialog: false,
    warehouses: [],
    shops: [],
    color: "",
    text: "",
    select_val: ["Staff", "Admin", "Seller"],
    headers: [
      { text: "Full Name", value: "username" },
      { text: "Email", value: "email" },
      { text: "Contact Number", value: "phone_number" },
      // { text: "Role", value: "role" },
      { text: "Profile Lock", value: "profile_status", align: "center" },
      { text: "Action", value: "status", align: "center" },
      // { text: "Actions", value: "actions" },
    ],
    users: [],
    contact: {},
    editedIndex: -1,
    editedItem: {
      email: "",
      password: "",
      confirm_password: "",
      role: "",
      username: "",
      phone_number: "",
    },
    defaultItem: {
      email: "",
      password: "",
      confirm_password: "",
      role: "",
      username: "",
      phone_number: "",
      is_staff: true,
    },
    addItem: {
      username: "",
      email: "",
      password: "",
      confirm_password: "",
      phone_number: "",
      select_role: ["Staff", "Admin", "Seller", "manager", "Merchant"],
      option: "",
      addhome: {},
    },
    dialog: false,
    dialog1: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    initialize() {
      axios
        .get("user/show_all_merchants/")
        .then((response) => {
          this.loading = false;
          this.users = response.data.data;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    makeInactive(item) {
      axios
        .get(`user/deactivate_merchant/${item.id}/`)
        .then((response) => {
          this.dialog = false;
          if (response.data.success) {
            this.createSnackbar("Merchant deactivated successfully");
            setTimeout(() => {
              location.reload();
            }, 1000);
          } else {
            this.createSnackbar("Something went wrong !!", "error");
          }
        })
        .catch((err) => {
          this.dialog = false;
          this.createSnackbar("Something went wrong !!", "error");
        });
    },

    makeActive(item) {
      axios
        .get(`user/activate_merchant/${item.id}/`)
        .then((response) => {
          this.dialog1 = false;
          if (response.data.success) {
            this.createSnackbar("Merchant activated successfully");
            setTimeout(() => {
              location.reload();
            }, 1000);
          } else {
            this.createSnackbar("Something went wrong !!", "error");
          }
        })
        .catch((err) => {
          this.dialog1 = false;
          this.createSnackbar("Something went wrong !!", "error");
        });
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.Editusercreatedialog = true;
    },

    deleteItem(item) {
      if (confirm("Do you really want to delete?")) {
        axios
          .post(`user/user_delete/${item.id}/`)
          .then((response) => {
            if (response.data.success) {
              this.users = this.users.filter(
                (contact) => contact.id !== item.id
              );
              this.text = "User has been deleted successfully";
              this.color = "success";
            } else {
              this.text = "Something went wrong !!";
              this.color = "error";
            }
            this.snackbar = true;
          })
          .catch((err) => {
            this.text = "Something went wrong !!";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        let values = this.editedItem;
        let index = this.editedIndex;

        axios
          .post(`user/update_user/${this.editedItem.id}/`, {
            email: values.email,
            password: values.pwd,
            role: values.role,
          })
          .then((response) => {
            Object.assign(this.users[index], values);
            this.Editusercreatedialog = false;
          });
      } else {
        let all_data = this.editedItem;

        if (
          this.editedItem.email &&
          this.editedItem.password &&
          this.editedItem.role
        ) {
          axios
            .post("user/create_user/", this.editedItem)
            .then((response) => {
              if (response.data.success) {
                this.users.push(response.data.data);
                this.text = "user has been created successfully";
                this.color = "success";
              } else {
                this.text = "Something went wrong !!";
                this.color = "error";
              }
              this.snackbar = true;
            })
            .catch((err) => {
              this.text = "Something went wrong !!";
              this.color = "error";
              this.snackbar = true;
            });
        } else {
          this.text = "Fill up all the field first !!";
          this.color = "error";
          this.snackbar = true;
        }
      }
      this.close();
    },
    adduser() {
      this.usercreatedialog = true;
    },
    saveconfirm() {
      if (this.addItem.role == "manager") {
        if (this.addItem.option == "Shop") {
          let datas = {
            email: this.addItem.email,
            username: this.addItem.username,
            phone_number: this.addItem.phone_number,
            shop_id: this.addItem.addhome.id,
            password: this.addItem.password,
            role: this.addItem.role,
          };
          // console.log("this is array after click" , datas)
          axios.post("manager/assign_manager/", datas).then((response) => {
            if (response.data.success) {
              this.text = response.data.message;
              this.color = "success";
              this.snackbar = true;
              this.usercreatedialog = false;
              this.$refs.userUploadform.reset();
            }
          });
        } else {
          let datas = {
            email: this.addItem.email,
            username: this.addItem.username,
            phone_number: this.addItem.phone_number,
            warehouse_id: this.addItem.addhome.id,
            password: this.addItem.password,
            role: this.addItem.role,
          };
          // console.log("this is array after click" , datas)
          axios.post("manager/assign_manager/", datas).then((response) => {
            if (response.data.success) {
              this.text = response.data.message;
              this.color = "success";
              this.snackbar = true;
              this.usercreatedialog = false;
            }
          });
        }
      } else {
        axios
          .post("user/create_user/", this.addItem)
          .then((response) => {
            //console.log("response data", response.data);
            if (response.data.success) {
              this.users.push(response.data.data);
              this.text = response.data.message;
              this.color = "success";
              this.snackbar = true;
              this.usercreatedialog = false;
            } else {
              this.text = response.data.message;
              this.color = "error";
              this.snackbar = true;
            }
          })
          .catch((err) => {
            this.text = response.data.message;
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    saveeditUser(item) {
      let values = {
        email: this.editedItem.email,
        password: this.editedItem.pwd,
        role: this.editedItem.role,
        phone_number: this.editedItem.phone_number,
        username: this.editedItem.username,
      };
      axios
        .post(`user/update_user/${this.editedItem.id}/`, values)
        .then((response) => {
          Object.assign(this.users[index], values);
          this.Editusercreatedialog = false;
        });
    },
    ViewManager(item) {
      let routeData = this.$router.resolve({
        name: `/supportdashboard/pages/manageruserdetails/${item.id}`,
      });
      routeData.href = `/supportdashboard/pages/manageruserdetails/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },

    unLockProfile(item) {
      axios
        .get(`user/unlock_merchant_profile/${item.id}/`)
        .then((response) => {
          if (response.data.success) {
            this.createSnackbar(
              "Merchant profile has been unlocked successfully"
            );
            setTimeout(() => {
              location.reload();
            }, 1000);
          } else {
            this.createSnackbar("Something went wrong !!", "error");
          }
        })
        .catch((err) => {
          this.createSnackbar("Something went wrong !!", "error");
        });
    },

    lockProfile(item) {
      axios
        .get(`user/lock_merchant_profile/${item.id}/`)
        .then((response) => {
          if (response.data.success) {
            this.createSnackbar(
              "Merchant profile has been locked successfully"
            );
            setTimeout(() => {
              location.reload();
            }, 1000);
          } else {
            this.createSnackbar("Something went wrong !!", "error");
          }
        })
        .catch((err) => {
          this.createSnackbar("Something went wrong !!", "error");
        });
    },

    createSnackbar(text, color = "success") {
      this.text = text;
      this.color = color;
      this.snackbar = true;
    },
  },
  created() {
    this.initialize();
  },
};
</script>


<style>
.v-sheet--offset {
  top: -10px;
  position: relative;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

